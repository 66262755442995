.hero-container
    flex-direction: column
    a, a:visited, a:hover, a:active
        color: inherit
        text-decoration: none
        outline: 0
    
.hero-img-container
    max-width: 100%
    width: 100%
    img
        width: 100%
        height: auto
        object-fit: cover
