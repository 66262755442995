@import '../../assets/sass/vars.sass'
.footer-app-version
  text-align: center
  color: #666
  font-family: GTWalsheimPro
#footer
  a, a:visited, a:hover, a:active
    color: inherit
    text-decoration: none
    outline: 0

  padding: 5rem 13.5rem
  max-width: 100%
  font-family: GTWalsheimPro
  font-size: 1.6rem
  color: #222A41
  text-align: center
  font-weight: bold
  display: grid
  grid-template-columns: 1fr 1fr 1fr

  .footer-contact
    display: grid
    grid-template-columns: 1fr 1fr
    svg
      width: 2.5rem
    :first-child
      margin: 0
      font-size: 2.6rem
      color: #0077C8
      text-align: left
      grid-column-start: 1
      grid-column-end: 3
    .footer-phone-number
      margin: 1.6rem 0
      font-size: 3.2rem
      text-align: left
    div
      display: flex
      justify-content: space-around
      align-items: center

  .footer-logo
    display: flex
    align-items: flex-end
    justify-content: center
    padding-bottom: 3rem

  .footer-download-app
    p
      margin: 0 0 2rem 0
      font-size: 2.6rem
      color: #0077C8
      font-weight: 700
      letter-spacing: -0.1px
    div
      display: flex
      justify-content: space-around

  .footer-privacy
    text-align: left
    grid-column-start: 1
    grid-column-end: 3
    font-weight: 300
  
  .footer-terms
    font-size: 1.8rem
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    .footer-line-separator
      background-color: #FE411A
      margin: 0px 10px
      width: 2px
      height: 30px

  @media (max-width: 996px)
    padding: 2.5rem 2.4rem
    color: #222A41
    text-align: center
    font-weight: bold
    display: grid
    grid-template-columns: 1fr

    .footer-contact
      display: flex
      flex-direction: column
      align-items: center
      grid-column-start: 1
      grid-column-end: 3
      p:first-of-type
        text-align: center
        letter-spacing: -1px
      div
        width: 70%
        display: flex
        justify-content: space-evenly
        align-items: center

    .footer-logo
      display: flex
      align-items: center
      justify-content: center
      grid-row-start: 1
      grid-column-start: 1
      grid-column-end: 3
      > a svg
        width: 16.7rem

    .footer-download-app
      grid-column-start: 1
      grid-column-end: 4
      margin-bottom: 2rem
      p
        margin-top: 3rem
        margin-bottom: 1rem
      div
        display: flex
        justify-content: space-around
        svg
          width: 12rem

    .footer-privacy
      text-align: left
      grid-column-start: 1
      grid-column-end: 3
      grid-row-start: 5
    
    .footer-terms
      width: 100%
      display: flex
      flex-direction: row
      justify-content: center
      grid-column-start: 1
      grid-column-end: 3
      font-size: 1.8rem
      text-align: center
      margin-bottom: 3rem

      .footer-line-separator
        background-color: #FE411A
        margin: 0px 3rem
        width: 2px
        height: 30px


